import React, { Fragment } from "react";
import { graphql, Link } from "gatsby";
import { get, assign, isNil } from "lodash";
import { RichText } from "prismic-reactjs";
import { Elements } from 'prismic-richtext';
import Img from "gatsby-image";
import Layout from "./layout";
import SEO from "./seo";
import DownloadAppCta from '../components/download-app-cta';
import ShareButtons from '../components/share-buttons';
import RegisterForNewsletter from '../components/register-for-newsletter';
import KickstarterCta from "../components/kickstarter-cta";
import { randomInRange } from '../utilities/math';
import "./post.scss";

const PostCtaTypes = {
  DOWNLOAD_THE_APP_COMPETE: 'download-the-app-compete',
  DOWNLOAD_THE_APP_WIN: 'download-the-app-win',
  DOWNLOAD_THE_APP_PLAY_LIVE_GAMES: 'download-the-app-play-live-games',
  KICKSTARTER_CTA: 'kickstarter-cta'
};

const PostCtaComponentTypes = {
  [PostCtaTypes.DOWNLOAD_THE_APP_COMPETE]: DownloadAppCta,
  [PostCtaTypes.DOWNLOAD_THE_APP_WIN]: DownloadAppCta,
  [PostCtaTypes.DOWNLOAD_THE_APP_PLAY_LIVE_GAMES]: DownloadAppCta,
  [PostCtaTypes.KICKSTARTER_CTA]: KickstarterCta
};

export const query = graphql`
  query($uid: String) {
    prismic {
      allPosts(uid: $uid) {
        edges {
          node {
            title
            date
            content
            author
            cta_1
            cta_2
            main_image
            main_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1280, srcSetBreakpoints: [1280], quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            main_image_mobile
            main_image_mobileSharp {
              childImageSharp {
                fluid(maxWidth: 640, srcSetBreakpoints: [320, 640], quality: 90) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            main_background_image
            main_background_imageSharp {
              childImageSharp {
                fluid(maxWidth: 1800, srcSetBreakpoints: [1800], quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            seo_image
          }
        }
      }
    }
  }
`;

const _renderCta = (post, ctaType) => {
  const content = get(post, ctaType, null);
  if (isNil(content)) return null;
  const contentType = get(content, ["0", "text"]);
  const Component = get(PostCtaComponentTypes, contentType, undefined);
  let componentProps = {};
  switch (contentType) {
    case PostCtaTypes.DOWNLOAD_THE_APP_PLAY_LIVE_GAMES:
      componentProps = {
        ...componentProps,
        ctaText: 'Download Escape The App to find & play hundreds of live video games now!'
      };
      break;
    case PostCtaTypes.DOWNLOAD_THE_APP_COMPETE:
      componentProps = {
        ...componentProps,
        ctaText: 'Download Escape The App to save your escape times and compete against other escape artists worldwide!'
      };
      break;
    case PostCtaTypes.DOWNLOAD_THE_APP_WIN:
      componentProps = {
        ...componentProps,
        ctaText: 'Download Escape The App to save your escape times, earn rewards and win escape game prizes!'
      };
      break;
    default: break;
  }
  return !isNil(Component) ? (
    <Component 
      {...componentProps} 
    />
  ) : null;
};

const _htmlSerializer = (type, element) => {
  switch(type) {
    case Elements.image: {
      const { url, alt } = element;
      return (
        <Fragment key={`blog-post-image--${randomInRange()}`}>
          <p className="blog-post-image">
            <img className="blog-post-image__image" src={url} alt={alt}></img>
            {isNil(alt) ? null : (
              <span className="blog-post-image__caption">{alt}</span>
            )}
          </p>
        </Fragment>
      );
    }
    default: 
      return null;
  }
};

const _renderSeo = post => {
  const { title } = post;
  let seoProps = {
    title: RichText.asText(title)
  };
  const seoImage = get(post, ['seo_image', 'url'], undefined);
  if (!isNil(seoImage)) {
    seoProps = assign(seoProps, { image: seoImage });
  }
  return (
    <SEO {...seoProps} />
  );
};

const Post = ({ data, location }) => {
  const edges = get(data, ['prismic', 'allPosts', 'edges'], []);
  const post = get(edges, ['0', 'node']);
  if (!post) return null;
  const { title, content, date, author } = post;
  const mainImage = get(post, ["main_imageSharp", "childImageSharp", "fluid"], undefined);
  const mainImageMobile = get(post, ["main_image_mobileSharp", "childImageSharp", "fluid"], undefined);
  const mainBackgroundImage = get(post, ["main_background_imageSharp", "childImageSharp", "fluid"], undefined);
  return (
    <Layout>
      {_renderSeo(post)}
      <div className="section section--image-header">
        <div className="image-header">
          {mainBackgroundImage ? (
            <div className={`image-header__background-image-wrapper`}>
              <Img
                className={`image-header__background-image`}
                fluid={mainBackgroundImage}
              />
            </div>
          ) : null}
        </div>
        <div className="section__inner section__inner--image-header">
          {mainImage ? (
            <div className={`image-header__image-wrapper image-header__image-wrapper--desktop`}>
              <Img
                className={`image-header__image image-header__image--desktop`}
                fluid={mainImage}
              />
            </div>
          ) : null}
          {mainImageMobile ? (
            <div className={`image-header__image-wrapper image-header__image-wrapper--mobile`}>
              <Img
                className={`image-header__image image-header__image--mobile`}
                fluid={mainImageMobile}
              />
            </div>
          ) : null}
          <h1>{RichText.asText(title)}</h1>
          <p>Posted on {date}{isNil(author) ? '' : ` by ${RichText.asText(author)}`}.</p>
        </div>
      </div>
      <div className="section section--blog-post">
        <div className="section__inner section__inner--blog-post">
          <RichText 
            render={content}
            htmlSerializer={_htmlSerializer}
          />
          <p>Posted on {date}{isNil(author) ? '' : ` by ${RichText.asText(author)}`}.</p>
          <ShareButtons url={location.href} />
        </div>
      </div>
      {_renderCta(post, 'cta_1')}
      {_renderCta(post, 'cta_2')}
      <div className="section section--blog-post-back">
        <Link className="anchor anchor--white anchor--blog-post-back" to="/blog">
          Go Back
        </Link>
      </div>
      <RegisterForNewsletter />
    </Layout>
  );
};

export default Post;
