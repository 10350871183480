import React from "react";
import {
  EmailShareButton,
  FacebookShareButton,
  RedditShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import EmailShareIcon from "../assets/svg/telegram-icon--white.svg";
import FacebookShareIcon from "../assets/svg/facebook-icon--white.svg";
import RedditShareIcon from "../assets/svg/reddit-icon--white.svg";
import TwitterShareIcon from "../assets/svg/twitter-icon--white.svg";
import WhatsappShareIcon from "../assets/svg/whatsapp-icon--white.svg";
import "./share-buttons.scss";

const ShareButtons = ({ url }) => (
  <div className="share-buttons">
    <EmailShareButton className="share-buttons__button share-buttons__button--email" url={url}>
      <EmailShareIcon className="share-buttons__button-icon share-buttons__button-icon--email" />
    </EmailShareButton>
    <FacebookShareButton className="share-buttons__button share-buttons__button--facebook" url={url}>
      <FacebookShareIcon className="share-buttons__button-icon share-buttons__button-icon--email" />
    </FacebookShareButton>
    <RedditShareButton className="share-buttons__button share-buttons__button--reddit" url={url}>
      <RedditShareIcon className="share-buttons__button-icon share-buttons__button-icon--email" />
    </RedditShareButton>
    <TwitterShareButton className="share-buttons__button share-buttons__button--twitter" url={url}>
      <TwitterShareIcon className="share-buttons__button-icon share-buttons__button-icon--email" />
    </TwitterShareButton>
    <WhatsappShareButton className="share-buttons__button share-buttons__button--whatsapp" url={url}>
      <WhatsappShareIcon className="share-buttons__button-icon share-buttons__button-icon--email" />
    </WhatsappShareButton>
  </div>
);

export default ShareButtons;
