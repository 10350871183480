import React from "react";
import { isNil } from "lodash";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import AppStoreLogo from "../assets/svg/app-store-logo.svg";
import PlayStoreLogo from "../assets/svg/play-store-logo.svg";

const DownloadAppCta = ({ ctaText, modifier }) => (
  isNil(ctaText) ? null : (
    <div className={`section section--${modifier}`}>
      <div className={`section__inner section__inner--${modifier}`}>
        <h3 className={`section__subheading section__subheading--${modifier}`}>
          {ctaText}
        </h3>
        <div className="store-logos store-logos--no-margin-bottom">
          <OutboundLink
            href="https://apps.apple.com/us/app/escape/id1460848804"
            target="_blank"
            rel="noreferrer"
            eventLabel="ios"
            eventCategory="download_app"
            eventAction="download_app_button_click">
            <AppStoreLogo className="store-logos__logo store-logos__logo--app-store" />
          </OutboundLink>
          <PlayStoreLogo className="store-logos__logo store-logos__logo--play-store" />
        </div>
      </div>
    </div>
  )
);

export default DownloadAppCta;
